<template>
  <button class="button w-100 h-100 shadow-5" :class="`${fgColor1}`" v-tooltip="title1" v-badge="badge1">
    <span class="button_lg" :class="bgColor1">
      <span class="button_sl"></span>
      <span class="button_text"><i v-if="icon1" :class="icon1" class="icon-inline fs-large mr-1"></i>{{ title1 }}</span>
    </span>
  </button>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "buttonEnat",
  setup() {},
  components: {
  },
  props: {
    title: {
      type: String,
      required: false
    },
    subtitle: {
      type: String,
      required: false
    },
    icon: {
      type: String,
      required: false
    },
    fgColor: {
      type: String,
      required: false
    },
    bgColor: {
      type: null,
      required: false
    },
    badge: {
      type: String,
      required: false
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  watch: {

  },
  computed: {
    title1: function () {
      if (this.title) return this.title;
      return '';
    },
    subtitle1: function () {
      if (this.subtitle) return this.subtitle;
      return '';
    },
    icon1: function () {
      if (this.icon) {
        return this.icon;
      }
      return '';
    },
    fgColor1: function () {
      if (this.fgColor) {
        return `fg-${this.fgColor}`;
      }
      return '';
    },
    bgColor1: function () {
      if (this.bgColor) {
        return `bg-${this.bgColor}`;
      }
      return '';
    },
    badge1: function () {
      if (this.badge) {
        return this.badge;
      }
      return null;
    },
  },
  mounted() {
  },
  methods: {
  },
});
</script>

<style lang="scss" scoped>
.button {
    font-family: "SystemFont", Helvetica, Arial, sans-serif;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border: none;
    background: none;
    cursor: pointer;
    position: relative;
    padding: 5px;
    margin-bottom: 10px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
    transition: all .15s ease;
  }
  
  .button::before,
  .button::after {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    left: 0;
    height: calc(50% - 5px);
    border: 1px solid #7D8082;
    transition: all .15s ease;
  }
  
  .button::before {
    top: 0;
    border-bottom-width: 0;
  }
  
  .button::after {
    bottom: 0;
    border-top-width: 0;
  }
  
  .button:active,
  .button:focus {
    outline: none;
  }
  
  .button:active::before,
  .button:active::after {
    right: 3px;
    left: 3px;
  }
  
  .button:active::before {
    top: 3px;
  }
  
  .button:active::after {
    bottom: 3px;
  }
  
  .button_lg {
    position: relative;
    display: block;
    padding: 10px 20px;
    overflow: hidden;
    box-shadow: inset 0px 0px 0px 1px transparent;
  }
  
  .button_lg::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 2px;
    height: 2px;
    background-color: #0f1923;
  }
  
  .button_lg::after {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 4px;
    height: 4px;
    background-color: #0f1923;
    transition: all .2s ease;
  }
  
  .button_sl {
    display: block;
    position: absolute;
    top: 0;
    bottom: -1px;
    left: -8px;
    width: 0;
    background-image: linear-gradient(to bottom right, transparent, #fffb00);
    transform: skew(-15deg);
    transition: all .2s ease;
  }
  
  .button_text {
    position: relative;
  }
  
  .button:hover {
    color: #0f1923 !important;
  }

  .button:hover::before,
  .button:hover::after {
    right: -3px;
    left: -3px;
  }
  
  .button:hover .button_sl {
    width: calc(100% + 15px);
  }
  
  .button:hover .button_lg::after {
    background-color: #fff;
  }
</style>